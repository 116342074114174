.input-field {
    .input-field-label {
        background-color: #DBE2EA;
        border: 1px solid #DBE2EA;
        border-radius: 4px 0px 0px 4px;
        color: #4F4F4F;
    }

    .input-field-input {
        border: 1px solid #DBE2EA;
        border-radius: 0px 4px 4px 0px;

        input {
            border:  none;
            width: 100%;
            margin: 0;
            padding: 0;
            color: #333333;
            background-color: transparent;

            &:focus{
                outline: none;
            }

            &::placeholder {
                color: #828282;
            }
            &:disabled{
                background-color: #FFFFFF;
            }
        }
    }
}
.view-switch {
    padding-right: 2.5em;
}
.view-switch .view-check-input {
    width: 3.2em;
    height: 1.9em;
    margin-right: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .view-switch .view-check-input {
        transition: none;
    }
}
.view-switch .view-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.view-check-input:active {
    filter: brightness(90%);
}
.view-check-input:checked {
    background-color: #15B44B;
    border-color: #15B44B;
}
.view-check-input {
    float: right;
    margin-right: -1.5em;
}

.view-check-input {
    width: 2em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
