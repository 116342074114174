
.app-stepper {
    margin-bottom: 1.5rem;

    .step {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #DBE2EA;
        margin: 0;
        padding: 0;

        &.completed-issuer {
            border-color: #0081F9;
            background-color: #0081F9;
        }
        &.completed-verifier {
            border-color: #7879F1;
            background-color: #7879F1;
        }

        &.active-issuer {
            border-color: #0081F9;
        }
        &.active-verifier {
            border-color: #7879F1;
        }

        &.last-issuer:not(.active-issuer):not(.completed-issuer) {
            border-color: #DBE2EA;
            background-color: #DBE2EA;
        }
        &.last-verifier:not(.active-verifier):not(.completed-verifier) {
            border-color: #DBE2EA;
            background-color: #DBE2EA;
        }

        .step-name {
            color: #828282;
            font-size: 14px;
        }
    }

    .connector {
        height: 2px;
        flex: 1 1 auto;    
        background-color: #DBE2EA;

        &.active-issuer {
            background-color: #0081F9;
        }
        &.active-verifier {
            background-color: #7879F1;
        }
    }
}
