@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-utilities';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';

@import 'styles/buttons';
@import 'styles/tables';
@import 'styles/forms';

.spacer {
  flex: 1 1 auto;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3) !important;
}