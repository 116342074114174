.btn-choose{
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08), 0px 4px 8px rgba(44, 39, 56, 0.08);
  border-radius: 4px;
  min-width: 100px;
  height: 35px;
  margin: 0 5px 5px 0;
}
.green{
  background-color: #27AE60;
  color: #FFFFFF;
  border: 1px solid #27AE60;

}
.white{
  background-color: #FFFFFF;
  color: #BDBDBD;
  border: 1px solid #BDBDBD;
}
