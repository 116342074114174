.display-none{
    display: none;
  }
  .box-copy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 40px 40px;
  
    width: 603px;
    height: 396px;
    left: 569px;
    top: 459px;
  
    background: #FFFFFF;
  
    border: 1px solid #DBE2EA;
    box-sizing: border-box;
  }
  .btbox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    width: 648px;
    min-height: 451px;
    left: 81px;
    top: 443px;
  }
  .btn-secure-chat{
    background: #FFFFFF;
    border: 1px solid #DBE2EA;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001), 0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 4px;
    padding:  0.9em 1.1em;
    margin: 0.5em 0 ;
  }
  .link{
    width: 100%;
    justify-content: space-around;
    align-items: center;
  
  }
  .link-value{
    width: 75%;
    background: #FFFFFF;
    padding: 0.4em 1.5em;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 4px;
  
  }
  .copy-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3em 1.5em;
  
  
  color: #FFFFFF;
    background: #7879F1;
    border-radius: 4px;
    border-color: #7879F1;
  }
  .qr-verifier{
    position: static;
    width: 155px;
    height: 155px;
    left: 40px;
    top: 201px;
    background: url(../../../../assets/images/qr-verifier.svg);
    flex: none;
    order: 4;
    flex-grow: 0;
    margin: 10px 0px;
  }
  