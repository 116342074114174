.did-document-success {
    .check {
        width: 80px;
        height: 80px;
        background: #15B44B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .message-block {
        h5 {
            color: #15B44B;
            font-weight: bold;
            font-size: 22px;
            margin: 0;
        }

        .transaction-id-label {
            margin: 0;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
        }

        .transaction-id {
            font-weight: normal;
            font-size: 18px;
            color: #333333;
            margin: 0;
        }
    }
}