.revoke-success-dialog{
  width: 456px;
  height: 384px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;


  .check {
    width: 80px;
    height: 80px;
    background: #15B44B;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
