.button-box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 1201px;
  height: 258.5px;
  left: 119px;
  top: 322px;
}
.button-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 1201px;
  height: 120px;
  left: 0px;
  top: 138.5px;
}
