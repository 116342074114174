.casper-signer-block {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #DBE2EA;
    box-sizing: border-box;
    border-radius: 10px;
}

.casper-signer-img {
    width: 90px;
    height: 90px;
}