
.page-title {
    h4 {
        color: #333333;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.25rem;
        margin: 0;
    }

    p {
        color: #828282;
        font-size: 1rem;
    }
}
